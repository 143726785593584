import React from "react";

import { FaGraduationCap } from "react-icons/fa";
import { AiFillFolder } from "react-icons/ai";
import { RiFileList3Fill } from "react-icons/ri";

import Me from "../../assets/me-closeup-nobg.png";
import "./about.css";

const About = () => {
  return (
    <section id="about">
      <h5>My Story</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={Me} alt="Me" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <a href="#experience">
              <article className="about__card">
                <RiFileList3Fill className="about__icon" />
                <h5>Experience</h5>
                <small>2000hrs+ spent coding</small>
              </article>
            </a>
            <a href="#portfolio">
              <article className="about__card">
                <AiFillFolder className="about__icon" />
                <h5>Portfolio</h5>
                <small>30+ Completed Projects</small>
              </article>
            </a>
            <a
              href="https://launchschool.com/courses"
              target="_blank"
              rel="noopener noreferrer"
            >
              <article className="about__card">
                <FaGraduationCap className="about__icon" />
                <h5>Education</h5>
                <small>Launch School</small>
              </article>
            </a>
          </div>
          <p>
            After building a strong foundation via Launch School's Core program,
            I've spent the last year honing my abilities as a developer. The
            creativity and problem solving aspects continue to captivate me
            everyday, and there is no better feeling than finding a unique
            solution to an issue you've been faced with. I have truly enjoyed
            the journey to this point, and aspire to continue expanding my
            skillset. Coding aside, I love making music digitally, cooking,
            traveling, and spending as much time outdoors as possible. I am a
            highly dedicated, passionate, and persistant, and am extremely
            confident in being a great asset to any company.
          </p>
          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
