import React, { useState, useRef } from "react";
import emailjs from "emailjs-com";
import { MdOutlineEmail } from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import { RiWhatsappLine } from "react-icons/ri";
import { MdOutlineLocationOn } from "react-icons/md";
import { Snackbar } from "@mui/material";

import "./contact.css";

const Contact = () => {
  const form = useRef();
  const [msgSentOpen, setMsgSentOpen] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_8swuufi",
        "template_g0xwbzb",
        form.current,
        "s9d-pB8Je4hJzb54u"
      )
      .then(
        (result) => {
          console.log(result.text);
          e.target.reset();
          setMsgSentOpen(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>
      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineLocationOn className="contact__option-icon" />
            <a href="javascript:;">
              <h5>Toronto, ON</h5>
            </a>
          </article>
          <article className="contact__option">
            <MdOutlineEmail className="contact__option-icon" />
            <a
              href="mailto:4122dpocsai@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h5>4122dpocsai@gmail.com</h5>
            </a>
          </article>
          <article className="contact__option">
            <FiPhone className="contact__option-icon" />
            <a
              href="tel:+1-647-502-6139"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h5>647-502-6139</h5>
            </a>
          </article>
          <article className="contact__option">
            <RiWhatsappLine className="contact__option-icon" />
            <a
              href="https://api.whatsapp.com/send?phone=16475026139"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h5>Send message</h5>
            </a>
          </article>
        </div>
        <form ref={form} onSubmit={(e) => sendEmail(e)}>
          <input
            type="text"
            name="name"
            placeholder="Your Full Name"
            required
          />
          <input type="email" name="email" placeholder="Your Email" required />
          <textarea
            name="message"
            placeholder="Your Message"
            rows="7"
            required
          ></textarea>
          <button type="submit" className="btn btn-primary">
            Send Message
          </button>
        </form>
        <Snackbar
          className="snackbar"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={msgSentOpen}
          autoHideDuration={800}
          onClose={() => setMsgSentOpen(false)}
          message="Message Sent!"
        />
      </div>
    </section>
  );
};

export default Contact;
