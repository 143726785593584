import React, { useState } from "react";
import "./nav.css";
import { AiOutlineHome, AiOutlineUser, AiOutlineFolder } from "react-icons/ai";
import { RiFileList3Line } from "react-icons/ri";
import { BiMessageDetail } from "react-icons/bi";

const Nav = () => {
  const [activeNav, setActiveNav] = useState("/#");

  return (
    <nav>
      <a
        href="/#"
        className={activeNav === "/#" ? "active" : ""}
        onClick={() => setActiveNav("/#")}
      >
        <AiOutlineHome />
      </a>
      <a
        href="#about"
        className={activeNav === "#about" ? "active" : ""}
        onClick={() => setActiveNav("#about")}
      >
        <AiOutlineUser />
      </a>
      <a
        href="#experience"
        className={activeNav === "#experience" ? "active" : ""}
        onClick={() => setActiveNav("#experience")}
      >
        <RiFileList3Line />
      </a>
      <a
        href="#portfolio"
        className={activeNav === "#portfolio" ? "active" : ""}
        onClick={() => setActiveNav("#portfolio")}
      >
        <AiOutlineFolder />
      </a>
      <a
        href="#contact"
        className={activeNav === "#contact" ? "active" : ""}
        onClick={() => setActiveNav("#contact")}
      >
        <BiMessageDetail />
      </a>
    </nav>
  );
};

export default Nav;
