import React from "react";

import Carousel from "react-material-ui-carousel";

import tourney_list from "../../assets/tourney_list.png";
import tourney_bracket from "../../assets/tourney_bracket.png";
import tourney_create from "../../assets/tourney_create.png";
import tourney_rr from "../../assets/tourney_rr.png";

import buzzwords_live from "../../assets/buzzwords_live.png";
import buzzwords_start from "../../assets/buzzwords_start.png";
import buzzwords_words_live from "../../assets/buzzwords_words_live.png";
import buzzwords_words_end from "../../assets/buzzwords_words_end.png";

import geogames_flags from "../../assets/geogames_flags.png";
import geogames_maps from "../../assets/geogames_maps.png";
import geogames_lookup from "../../assets/geogames_lookup.png";
import geogames_menu from "../../assets/geogames_menu.png";
import geogames_highlow from "../../assets/geogames_highlow.png";
import geogames_lists from "../../assets/geogames_lists.png";

import goodday_data from "../../assets/goodday_data.png";
import goodday_data_2 from "../../assets/goodday_data_2.png";
import goodday_itemdata from "../../assets/goodday_itemdata.png";
import goodday_scores from "../../assets/goodday_scores.png";
import goodday_update from "../../assets/goodday_update.png";

import wordle_speed_live from "../../assets/wordle_speed_live.png";
import wordle_speed_results from "../../assets/wordle_speed_results.png";
import wordle_solver from "../../assets/wordle_solver.png";
import wordle_classic from "../../assets/wordle_classic.png";

import "./portfolio.css";

const projects = [
  {
    title: "Good Day",
    images: [
      goodday_data,
      goodday_data_2,
      goodday_itemdata,
      goodday_scores,
      goodday_update,
    ],

    description:
      "An easy-to-use lifestyle tracker where you set the standards and define your own success.",
    githubHref: "https://github.com/dpocsai/goodday",
    liveDemoHref: "https://dpocsai-good-day.netlify.app",
  },
  {
    title: "GeoGames",
    images: [
      geogames_flags,
      geogames_maps,
      geogames_lookup,
      geogames_lists,
      geogames_highlow,
      geogames_menu,
    ],

    description:
      "A variety of geography minigames including maps, capitals, flags, country lists and more",
    githubHref: "https://github.com/dpocsai/GeoGames",
    liveDemoHref: "https://geogames-dpocsai.netlify.app/",
  },
  {
    title: "Tourney",
    images: [tourney_list, tourney_bracket, tourney_create, tourney_rr],
    description:
      "A custom tournament generating app with built-in brackets, dynamic standings and a dark/light mode toggle",
    githubHref: "https://github.com/dpocsai/tourney-client",
    liveDemoHref: "https://tourney.netlify.app/",
  },
  {
    title: "BuzzWords",
    images: [
      buzzwords_live,
      buzzwords_start,
      buzzwords_words_live,
      buzzwords_words_end,
    ],

    description:
      "An upgraded version of the popular New York Times game 'Spelling Bee', with added features and improved UX design",
    githubHref:
      "https://github.com/dpocsai/dpocsai.github.io/tree/main/buzzwords",
    liveDemoHref:
      "https://dpocsai.github.io/buzzwords/index.html?letters=YTNIGHA",
  },
];

const Portfolio = () => {
  const renderImages = (images) => {
    return images.map((img, idx) => {
      return (
        <img src={img} alt="" key={idx} className="portfolio__item-image" />
      );
    });
  };
  const renderProjects = (projects) => {
    return projects.map(
      ({ title, images, description, githubHref, liveDemoHref }) => {
        return (
          <article className="portfolio__item" key={title}>
            <Carousel>{renderImages(images)}</Carousel>

            <h3>{title}</h3>
            <small>{description}</small>
            <div className="portfolio__item-buttons">
              <a
                href={githubHref}
                target="_blank"
                rel="noopener noreferrer"
                className="btn"
              >
                GitHub
              </a>
              <a
                href={liveDemoHref}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary"
              >
                Live Demo
              </a>
            </div>
          </article>
        );
      }
    );
  };
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {renderProjects(projects)}
      </div>
    </section>
  );
};

export default Portfolio;
