import React from "react";
import Dpocsai_Resume from "../../assets/Dpocsai_Resume.PDF";

const HeaderButtons = () => {
  return (
    <div className="header__buttons">
      <a href={Dpocsai_Resume} download className="btn">
        Download CV
      </a>
      <a href="#contact" className="btn btn-primary">
        Contact Me
      </a>
    </div>
  );
};

export default HeaderButtons;
